






































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AllianzDepositRecurringDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-dialog-view-model';

@Component({
  name: 'RecurringDepositDialog',
  components: {
    RecurringDeposit: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDeposit.vue'),
    RecurringDepositGoalsDistribution: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositGoalsDistribution.vue'),
    RecurringDepositConfirmation: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositConfirmation.vue'),
    RecurringDepositResult: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositResult.vue'),
    SuspendContributionConfirmation: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/SuspendContributionConfirmation.vue'),
  },
})
export default class RecurringDepositDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  edit_recurring_contributions_model = Vue.observable(
    new AllianzDepositRecurringDialogViewModel(this),
  );

  endProcess() {
    this.edit_recurring_contributions_model.resetProperties();
    this.synced_is_open = false;
  }

  created() {
    this.edit_recurring_contributions_model.initialize();
  }
}
