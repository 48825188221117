import TYPES from '@/types';
import Vue from 'vue';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  ActivateRecurringContributionCommand,
} from '@/modules/my-investment/allianz/allianz-account/application/commands';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  SetRecurringContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/set-recurring-contributions-state-manager';
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import {
  SearchByCustomerDto,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositRecurringDialogViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_COMMAND)
  private readonly activate_recurring_contribution_command!: ActivateRecurringContributionCommand;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.SET_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly set_recurring_contributions_state_manager!:
    SetRecurringContributionsStateManager;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible_deposit_modal';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  readonly view: Vue;

  is_loading = false;

  data_loaded = false;

  allianz_account: AllianzAccountEntity = new State().account;

  deposit_recurring = {
    source_account: '',
    destination_account: '',
    collection_day: 0,
    amount: '0.00',
    new_collection_day: 0,
    new_amount: '0.00',
    periodicity: '',
  }

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  current_step = 1;

  readonly total_steps = 4;

  is_any_goal_active = false;

  steps = [
    {
      component: 'SuspendContributionConfirmation',
    },
    {
      component: 'RecurringDeposit',
    },
    {
      component: 'RecurringDepositGoalsDistribution',
    },
    {
      component: 'RecurringDepositConfirmation',
    },
    {
      component: 'RecurringDepositResult',
    },
  ];

  constructor(view: Vue) {
    this.view = view;
  }

  set_state = this.set_recurring_contributions_state_manager.state;

  get show_stepper_header() {
    return this.current_step > 0 && this.current_step < 4;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('steps_with_balance');

  nextStep = () => {
    if (this.current_step < this.total_steps) {
      if (this.current_step === 1 && !this.is_any_goal_active) {
        this.current_step += 2;
      } else {
        this.current_step += 1;
      }
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      if (this.current_step === 3 && !this.is_any_goal_active) {
        this.current_step -= 2;
      } else {
        this.current_step -= 1;
      }
    }
  }

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  resetState = () => {
    this.set_state.item.last_collection_day = 0;
    this.set_state.item.last_contribution_amount = '';
    this.set_state.item.home_desired = false;
    this.set_state.item.have_more_than_one_contribution = false;
    this.set_state.item.wants_more_than_one_contribution = false;
    this.set_state.item.wants_back_to_one_contribution = false;
    this.set_state.item.current_contribution = 0;
    this.set_state.item.contributions = [];
    this.set_state.item.show_suspend_contributions = false;
    this.set_state.item.contributions_saved = false;
  }

  resetProperties = () => {
    this.current_step = 1;
    this.view.$emit('endProcess');
  }

  suspendContribution = () => {
    this.current_step = 0;
  }

  setCorrectMenuSteps = () => {
    if (this.is_any_goal_active) {
      this.menu_steps = this.translate('steps_with_balance');
    } else {
      this.menu_steps = this.translate('steps_without_balance');
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
      await this.loadActiveGoals();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      const goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      this.is_any_goal_active = goals.length > 0;
      this.setCorrectMenuSteps();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  initialize = async () => {
    this.is_loading = true;
    this.resetState();
    await this.loadInvestmentProducts();
    this.is_loading = false;
  }
}
